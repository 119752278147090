// log
import store from "../store";

const fetchDataSubRequest = () => {
  return {
    type: "CHECK_DATASUB_REQUEST",
  };
};

const fetchDataSubSuccess = (payload) => {
  return {
    type: "CHECK_DATASUB_SUCCESS",
    payload: payload,
  };
};

const fetchDataSubFailed = (payload) => {
  return {
    type: "CHECK_DATASUB_FAILED",
    payload: payload,
  };
};

export const fetchDataSub = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataSubRequest());
    try {
      let isAlEnabled = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled()
        .call();
      let isPsMPEnabled = await store
        .getState()
        .blockchain.smartContract.methods.isPublicSaleMPEnabled()
        .call();
      let isPsEnabled = await store
        .getState()
        .blockchain.smartContract.methods.isPublicSaleEnabled()
        .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSubSuccess({
          isAlEnabled,
          isPsEnabled,
          isPsMPEnabled,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataSubFailed("Could not load data from contract."));
    }
  };
};
