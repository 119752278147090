import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
// import { isMobile } from "react-device-detect";
// import { Canvas } from "@react-three/fiber";
// import Model from "./Model";
// import * as Loader from "react-loader-spinner";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  /* font-family: BlackOpsOne; */
  font-family: "gravesend-sans", sans-serif !important;
  font-weight: 500;
  font-style: normal;
  color: #000;
  font-size: 3rem;
  font-weight: 800;
  border-radius: 5rem;
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
  border: none;
  background-color: rgba(255, 255, 255, 0.4);
  /* animation: gradation 10.7s ease-in infinite; */
  :active {
    padding: 0rem 1rem;
  }
  :hover {
    color: #555;
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  border-radius: 10px;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  width: 33vw;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 1rem;
  display: inline-block;
  border-radius: 50px;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  background-color: #333;
  width: 33vw;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-image: url("/config/images/main/exo1.png");
  background-size: cover;
  background-position: top;
  padding: 0;
  height: 100vh;
  border-radius: 2px;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledIncDecButton = styled.button`
  font-family: BlackOpsOne;
  padding: 10px;
  border-radius: 2px;
  margin: 0 2px;
  border: solid 1px #333;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const MainBox = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export const StyledLogo = styled.img`
  width: ${({ flex }) => (flex ? flex : "33%")};
  height: auto;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  font-family: "gravesend-sans", sans-serif !important;
  font-weight: 500;
  font-style: normal;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click "Mint" to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWL, setmintAmountWL] = useState(1);
  const [mintAmountAL, setMintAmountAL] = useState(1);
  const [mintAmountPBMP, setMintAmountPBMP] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWL, setmintedWL] = useState(0);
  const [mintedAL, setmintedAL] = useState(0);
  const [whitelistProof, setWhitelistProof] = useState("");
  const [whitelistValid, setWhitelistValid] = useState(false);

  const [allowlistProof, setAllowlistProof] = useState("");
  const [allowlistValid, setAllowlistValid] = useState(false);

  const [publistProof, setPublistProof] = useState("");
  const [publistValid, setPublistValid] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const fetchDatas = (account) => {
  //   dispatch(fetchData(account));
  //   dispatch(fetchDataSub(account));
  // };

  const getWhitelist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let whitelist = require("./data/whitelist.json");

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setWhitelistProof(proof);
          setWhitelistValid(receipt);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
      // setWhitelistProof(proof);
      // setWhitelistValid(valid);
      console.log("WL:proof/" + proof);
      console.log("WL:root/" + root);
      // console.log(whitelistValid);
    }
  };

  const getAllowlist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let allowlist = require("./data/allowlist.json");

      let hashedAddresses = allowlist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isAllowlisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setAllowlistProof(proof);
          setAllowlistValid(receipt);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      console.log("AL:proof/" + allowlistProof);
      console.log("AL:root/" + root);
      // console.log(AllowlistValid);
    }
  };

  const getPublist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let publist = require("./data/publist.json");

      let hashedAddresses = publist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isPubliclisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setPublistProof(proof);
          setPublistValid(receipt);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
      // setWhitelistProof(proof);
      // setWhitelistValid(valid);
      console.log("PB:proof/" + proof);
      console.log("PB:root/" + root);
      // console.log(whitelistValid);
    }
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(500)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setPublicSaleMPEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleMPEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setWLSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const checkMintedWL = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWL(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const checkMintedAL = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .alMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedAL(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI("ar://1OZNxScRLDelLrU33Axew3DZIaA8lCqHf7XzBmD3hBk/0/")
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTsWl = () => {
    let cost = CONFIG.WEI_COSTWL;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountWL);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(mintAmountWL, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedWL();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTsAl = () => {
    let cost = CONFIG.WEI_COSTAL;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountAL);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .allowlistMint(mintAmountAL, allowlistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedAL();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTsPbMP = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountPBMP);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .publicMintMP(mintAmountPBMP, publistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const decrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount - diffNum;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount + diffNum;
    if (newMintAmount > CONFIG.MAX_MINTOT) {
      newMintAmount = CONFIG.MAX_MINTOT;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountPBMP = (diffNum) => {
    let newMintAmountPBMP = mintAmountPBMP - diffNum;
    if (newMintAmountPBMP < 1) {
      newMintAmountPBMP = 1;
    }
    setMintAmountPBMP(newMintAmountPBMP);
  };

  const incrementMintAmountPBMP = (diffNum) => {
    let newMintAmountPBMP = mintAmountPBMP + diffNum;
    if (newMintAmountPBMP > CONFIG.MAX_MINTOT) {
      newMintAmountPBMP = CONFIG.MAX_MINTOT;
    }
    setMintAmountPBMP(newMintAmountPBMP);
  };

  const decrementmintAmountWL = (diffNum) => {
    let newmintAmountWL = mintAmountWL - diffNum;
    if (newmintAmountWL < 1) {
      newmintAmountWL = 1;
    }
    setmintAmountWL(newmintAmountWL);
  };

  const incrementmintAmountWL = (diffNum) => {
    let newmintAmountWL = mintAmountWL + diffNum;
    let maxNum = CONFIG.MAX_MINTWL - mintedWL;
    if (newmintAmountWL > maxNum) {
      newmintAmountWL = maxNum;
    }
    setmintAmountWL(newmintAmountWL);
  };

  const decrementMintAmountAL = (diffNum) => {
    let newMintAmountAL = mintAmountAL - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL(newMintAmountAL);
  };

  const incrementMintAmountAL = (diffNum) => {
    let newMintAmountAL = mintAmountAL + diffNum;
    let maxNum = CONFIG.MAX_MINTAL - mintedAL;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL(newMintAmountAL);
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getWhitelist();
    getAllowlist();
    getPublist();
    checkMinted();
    checkMintedWL();
    checkMintedAL();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };
  useEffect(() => {
    getConfig();
    getData();
    checkMinted();
    checkMintedWL();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen
      style={{
        display: "block",
      }}
    >
      <s.Screen
        style={{
          display: "block",
          backgroundImage: "url('/config/images/background.png')",
          position: "fixed",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          zIndex: -100,
        }}
      ></s.Screen>
      <MainBox
        flex={1}
        ai={"center"}
        style={{
          padding: 0,
          height: "100vh",
          zIndex: 1,
        }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/background.png" : null}
        image={null}
        // image={isMobile ? "/config/images/pcbg.png" : "/config/images/pcbg.png"}
        // image={isMobile ? null : null}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 0 }}
          className={"kv"}
          test
        >
          <MainContainer
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{ width: "100vw", height: "100vh", overflowX: "hidden" }}
          >
            <span className="features">
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    完売！！ありがとうございます！！今後ともよろしくお願いします。
                  </s.TextTitle>
                </>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ||
                  blockchain.account === undefined ? (
                    <s.Container ai={"center"} jc={"center"}>
                      {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription> */}
                      <s.SpacerSmall
                        style={{
                          height: "20vh",
                          // animation: "1s linear forwards mintbutton",
                        }}
                      />
                      <StyledLogo
                        alt={"logo"}
                        src={"/config/images/title.png"}
                      />
                      <s.SpacerSmall
                        style={{
                          height: "20vh",
                          // animation: "1s linear forwards mintbutton",
                        }}
                      />

                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        MINT
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <s.Container
                      ai={"center"}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.3)",
                        padding: 10,
                        color: "white",
                      }}
                    >
                      <StyledLogo
                        width={"20%"}
                        alt={"logo"}
                        src={"/config/images/title.png"}
                      />

                      <s.TextTitle
                        style={{ textAlign: "center", color: "white" }}
                      >
                        EXO-磁力代理行動強化外骨格-
                      </s.TextTitle>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "white" }}
                      >
                        WL Sale：2/12(日) 21:00〜
                      </s.TextTitle>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "white" }}
                      >
                        AL Sale：2/13(月) 21:00〜
                      </s.TextTitle>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "white" }}
                      >
                        PB Sale：2/14(火) 21:00〜
                      </s.TextTitle>

                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          fontSize: 50,
                          fontWeight: "bold",
                          color: "var(--accent-text)",
                        }}
                      >
                        {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                      </s.TextTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--primary-text)",
                        }}
                      >
                        <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                          {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                        </StyledLink>
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        1 {CONFIG.SYMBOL} costs
                      </s.TextTitle>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {"WL:"}
                        {CONFIG.DISPLAY_COSTWL}
                        {CONFIG.NETWORK.SYMBOL}
                        {"(Max "}
                        {CONFIG.MAX_MINTWL}
                        {"mint)"}
                      </s.TextTitle>

                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {"AL:"}
                        {CONFIG.DISPLAY_COSTAL}
                        {CONFIG.NETWORK.SYMBOL}
                        {"(Max "}
                        {CONFIG.MAX_MINTAL}
                        {"mint)"}
                      </s.TextTitle>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {"Public:"}
                        {CONFIG.DISPLAY_COST}
                        {CONFIG.NETWORK.SYMBOL}
                        {"(Max ∞)"}
                      </s.TextTitle>
                      <s.SpacerXSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          fontSize: 15,
                        }}
                      >
                        Excluding gas fees.（別途ガス代が必要です）
                      </s.TextDescription>
                      <s.SpacerSmall />
                      {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                      >
                      {"接続アドレス："+blockchain.account}
                      </s.TextDescription> */}
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>

                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      ></s.TextDescription>
                      {datasub.isPsEnabled ||
                      datasub.isPsMPEnabled ||
                      (whitelistValid && data.isWlEnabled) ||
                      (allowlistValid && datasub.isAlEnabled) ? (
                        <>
                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              fontSize: 25,
                              fontWeight: "bold",
                              color: "var(--accent-text)",
                            }}
                          >
                            {data.isWlEnabled ? "WL:" : ""}
                            {datasub.isAlEnabled ? "AL:" : ""}
                            {datasub.isPsMPEnabled || datasub.isPsEnabled
                              ? "PB:"
                              : ""}

                            {"Now On Sale!"}
                          </s.TextTitle>
                        </>
                      ) : (
                        <>
                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              fontWeight: "bold",
                              color: "var(--accent-text)",
                            }}
                          >
                            {"セール準備中..."}
                          </s.TextTitle>
                        </>
                      )}

                      {/* ホワイトリスト所有者の方ここから */}
                      {whitelistValid ? (
                        <>
                          {datasub.isPsMPEnabled || datasub.isPsEnabled ? (
                            <></>
                          ) : (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"あなたはホワイトリスト所有者です("}
                                {mintedWL}
                                {"/"}
                                {CONFIG.MAX_MINTWL}
                                {")"}
                              </s.TextDescription>
                            </>
                          )}

                          {data.isWlEnabled ? (
                            <>
                              {CONFIG.MAX_MINTWL <= mintedWL ? (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                      }}
                                    >
                                      {"WL MINTありがとうございました"}
                                    </s.TextDescription>
                                  </s.Container>
                                </>
                              ) : (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementmintAmountWL(
                                          CONFIG.MAX_MINTWL
                                        );
                                        getData();
                                      }}
                                    >
                                      -{CONFIG.MAX_MINTWL}
                                    </StyledIncDecButton>
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementmintAmountWL(1);
                                        getData();
                                      }}
                                    >
                                      -1
                                    </StyledIncDecButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        padding: 20,
                                      }}
                                    >
                                      {mintAmountWL}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementmintAmountWL(1);
                                        getData();
                                      }}
                                    >
                                      +1
                                    </StyledIncDecButton>
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementmintAmountWL(
                                          CONFIG.MAX_MINTWL
                                        );
                                        getData();
                                      }}
                                    >
                                      +{CONFIG.MAX_MINTWL}
                                    </StyledIncDecButton>
                                  </s.Container>
                                  <s.SpacerSmall />
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTsWl();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "BUSY"
                                        : mintAmountWL + " MINT"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"ホワイトリストセールは開始していません"}
                              </s.TextDescription> */}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"このアドレスはアローリストに含まれていません。"}
                        </s.TextDescription> */}
                        </>
                      )}
                      {allowlistValid ? (
                        <>
                          {datasub.isPsMPEnabled || datasub.isPsEnabled ? (
                            <></>
                          ) : (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"あなたはアローリスト所有者です("}
                                {mintedAL}
                                {"/"}
                                {CONFIG.MAX_MINTAL}
                                {")"}
                              </s.TextDescription>
                            </>
                          )}
                          {datasub.isAlEnabled ? (
                            <>
                              {CONFIG.MAX_MINTAL <= mintedAL ? (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                      }}
                                    >
                                      {"AL MINTありがとうございました"}
                                    </s.TextDescription>
                                  </s.Container>
                                </>
                              ) : (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmountAL(
                                          CONFIG.MAX_MINTAL
                                        );
                                        getData();
                                      }}
                                    >
                                      -{CONFIG.MAX_MINTAL}
                                    </StyledIncDecButton>
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmountAL(1);
                                        getData();
                                      }}
                                    >
                                      -1
                                    </StyledIncDecButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        padding: 20,
                                      }}
                                    >
                                      {mintAmountAL}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmountAL(1);
                                        getData();
                                      }}
                                    >
                                      +1
                                    </StyledIncDecButton>
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmountAL(
                                          CONFIG.MAX_MINTAL
                                        );
                                        getData();
                                      }}
                                    >
                                      +{CONFIG.MAX_MINTAL}
                                    </StyledIncDecButton>
                                  </s.Container>
                                  <s.SpacerSmall />
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTsAl();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "BUSY"
                                        : mintAmountAL + " MINT"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              > */}
                              {/* {"アローリストセールは開始していません"} */}
                              {/* </s.TextDescription> */}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {/* ここからパブリックセール */}
                      {/* 通常パブセ */}
                      {datasub.isPsEnabled ? (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {"パブリックセール("}
                            {minted}
                            {"mint)"}
                          </s.TextDescription>

                          {CONFIG.MAX_MINT == minted ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"MINTいただきありがとうございました"}
                              </s.TextDescription>
                            </>
                          ) : (
                            <>
                              <s.Container
                                ai={"center"}
                                jc={"center"}
                                fd={"row"}
                              >
                                <StyledIncDecButton
                                  style={{ lineHeight: 0.4 }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount(5);
                                    getData();
                                  }}
                                >
                                  -5
                                </StyledIncDecButton>
                                <StyledIncDecButton
                                  style={{ lineHeight: 0.4 }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount(1);
                                    getData();
                                  }}
                                >
                                  -1
                                </StyledIncDecButton>
                                <s.SpacerMedium />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                    padding: 20,
                                  }}
                                >
                                  {mintAmount}
                                </s.TextDescription>
                                <s.SpacerMedium />
                                <StyledIncDecButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount(1);
                                    getData();
                                  }}
                                >
                                  +1
                                </StyledIncDecButton>
                                <StyledIncDecButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount(5);
                                    getData();
                                  }}
                                >
                                  +5
                                </StyledIncDecButton>
                              </s.Container>
                              <s.SpacerSmall />
                              <s.Container
                                ai={"center"}
                                jc={"center"}
                                fd={"row"}
                              >
                                <StyledButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTs();
                                    getData();
                                  }}
                                >
                                  {claimingNft ? "BUSY" : mintAmount + " MINT"}
                                </StyledButton>
                              </s.Container>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {/* {"パブリックセールは開始していません"} */}
                          </s.TextDescription>
                        </>
                      )}
                      {/* 通常パブセここまで */}
                      {/* MPパブセ */}
                      {publistValid ? (
                        <>
                          {/* <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {"あなたはパブリックセール参加者です("}
                            {minted}
                            {"/∞)"}
                          </s.TextDescription> */}
                        </>
                      ) : (
                        <></>
                      )}
                      {publistValid ? (
                        <>
                          {datasub.isPsMPEnabled ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"パブリックセール("}
                                {minted}
                                {"minted)"}
                              </s.TextDescription>

                              {CONFIG.MAX_MINT == minted ? (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"MINTいただきありがとうございました"}
                                  </s.TextDescription>
                                </>
                              ) : (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmountPBMP(5);
                                        getData();
                                      }}
                                    >
                                      -5
                                    </StyledIncDecButton>
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmountPBMP(1);
                                        getData();
                                      }}
                                    >
                                      -1
                                    </StyledIncDecButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        padding: 20,
                                      }}
                                    >
                                      {mintAmountPBMP}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmountPBMP(1);
                                        getData();
                                      }}
                                    >
                                      +1
                                    </StyledIncDecButton>
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmountPBMP(5);
                                        getData();
                                      }}
                                    >
                                      +5
                                    </StyledIncDecButton>
                                  </s.Container>
                                  <s.SpacerSmall />
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTsPbMP();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "BUSY"
                                        : mintAmountPBMP + " MINT"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {"パブリックセールは開始していません"}
                          </s.TextDescription> */}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* MPパブセここまで */}
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        NFT Market Place
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        <StyledLink
                          target={"_blank"}
                          href={CONFIG.MARKETPLACE_LINK}
                        >
                          {CONFIG.MARKETPLACE}
                        </StyledLink>
                      </s.TextDescription>
                      <s.SpacerLarge />

                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButtonReload
                          onClick={(e) => {
                            e.preventDefault();
                            getReload();
                          }}
                        >
                          {claimingNft ? "Busy" : "RELOAD"}
                        </StyledButtonReload>
                      </s.Container>
                    </s.Container>
                  )}
                </>
              )}
            </span>

            {/* オペレーターコマンド */}
            {blockchain.account === "" ||
            blockchain.smartContract === null ||
            blockchain.account === undefined ? (
              <></>
            ) : (
              <>
                {data.deployer == blockchain.account ? (
                  <>
                    <div
                      // ai={"center"}
                      style={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                        }
                      }
                    >
                      {!data.isWlEnabled ? (
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setWLSaleEnable(true);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "WL.Start"}
                        </StyledButtonOparator>
                      ) : (
                        <>
                          <StyledButtonOparatorOff
                            onClick={(e) => {
                              e.preventDefault();
                              setWLSaleEnable(false);
                              getData();
                            }}
                          >
                            {claimingNft ? "Busy" : "WL.Stop"}
                          </StyledButtonOparatorOff>
                        </>
                      )}

                      {!datasub.isAlEnabled ? (
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setALSaleEnable(true);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "AL.Start"}
                        </StyledButtonOparator>
                      ) : (
                        <>
                          <StyledButtonOparatorOff
                            onClick={(e) => {
                              e.preventDefault();
                              setALSaleEnable(false);
                              getData();
                            }}
                          >
                            {claimingNft ? "Busy" : "AL.Stop"}
                          </StyledButtonOparatorOff>
                        </>
                      )}

                      {!datasub.isPsMPEnabled ? (
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setPublicSaleMPEnable(true);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "PBMP.Start"}
                        </StyledButtonOparator>
                      ) : (
                        <>
                          <StyledButtonOparatorOff
                            onClick={(e) => {
                              e.preventDefault();
                              setPublicSaleMPEnable(false);
                              getData();
                            }}
                          >
                            {claimingNft ? "Busy" : "PBMP.Stop"}
                          </StyledButtonOparatorOff>
                        </>
                      )}

                      {!datasub.isPsEnabled ? (
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setPublicSaleEnable(true);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "PB.Normal.Start"}
                        </StyledButtonOparator>
                      ) : (
                        <>
                          <StyledButtonOparatorOff
                            onClick={(e) => {
                              e.preventDefault();
                              setPublicSaleEnable(false);
                              getData();
                            }}
                          >
                            {claimingNft ? "Busy" : "PB.Normal.Stop"}
                          </StyledButtonOparatorOff>
                        </>
                      )}

                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setBaseUrl();
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "URL.Set"}
                      </StyledButtonOparator>

                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setReveal();
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "REVEAL"}
                      </StyledButtonOparator>

                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          withdraw();
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "WITHDRAW"}
                      </StyledButtonOparator>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {/* オペレーターコマンド */}
          </MainContainer>
          {/* 
          <Suspense
            fallback={
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <Loader.TailSpin />
                <Loader.TailSpin />
                <Loader.TailSpin />
              </s.Container>
            }
          >
            <s.Container flex={1} jc={"center"} ai={"center"}>
              <div id="canvas-container">
                <Canvas>
                  <Model position={[0, 0, 0]} />
                </Canvas>
              </div>
            </s.Container>
          </Suspense>
 */}
        </ResponsiveWrapper>
      </MainBox>
    </s.Screen>
  );
}

export default App;
